import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '../store'
import {hasPermision, hasPermissions} from '../helper/helper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/auth/Login.vue'),
    meta: {
      layout: 'simple'
    }
  },
  {
    path: '/auth/reset',
    name: 'reset-passw',
    component: () => import('../views/auth/Reset.vue'),
    meta: {
      layout: 'simple',
      requiresLogin: true
    }
  },
  {
    path: '/customers/link',
    name: 'generate-link',
    component: () => import('../views/generate-register-link/PhoneInput.vue'),
    meta: {
      layout: 'external',
      requiresLogin: true
    }
  },
  {
    path: '/binding',
    name: 'binding',
    component: () => import('../views/binding/binding.vue'),
    meta: {
      layout: 'external',
      requiresLogin: true
    }
  },
  {
    path: '/customers/link/signup',
    name: 'generate-link-signup',
    component: () => import('../views/generate-register-link/SignUp.vue'),
    meta: {
      layout: 'external'
    }
  },
  {
    path: '/auth/reset/:token',
    name: 'reset-passw',
    component: () => import('../views/auth/Reset.vue'),
    meta: {
      layout: 'simple'
    }
  },
  {
    path: '/auth/forget',
    name: 'forget-passw',
    component: () => import('../views/auth/Forget.vue'),
    meta: {
      layout: 'simple'
    }
  },
  {
    path: '/email/verify/:token',
    name: 'email-verify',
    component: () => import('../views/email/Verify.vue'),
    meta: {
      layout: 'external'
    }
  },
  {
    path: '/email/verify/:token/:lang',
    name: 'email-verify-lang',
    component: () => import('../views/email/Verify.vue'),
    meta: {
      layout: 'external'
    }
  },
  {
    path: '/customers',
    name: 'user-list',
    component: () => import('../views/customer/Userlist.vue'),
    meta: {
      requiresLogin: true,
      permissions: ['CanViewBindedCustomer', 'CanViewAllCustomer']
    }
  },
  {
    path: '/news-list',
    name: 'news-list',
    component: () => import('../views/news/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewNews'
    }
  },
  {
    path: '/news',
    name: 'news-create',
    component: () => import('../views/news/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddNews'
    }
  },
  {
    path: '/news/:id',
    name: 'news-edit',
    component: () => import('../views/news/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewNews'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => {},
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/customers/information/:uid',
    name: 'user-detail',
    component: () => import('../views/user-detail/UserDetail.vue'),
    meta: {
      requiresLogin: true,
      permissions: ['CanViewBindedCustomer', 'CanViewAllCustomer']
    }
  },
  {
    path: '/banners',
    name: 'banner-list',
    meta: {
      requiresLogin: true,
      permissions: 'CanViewBanner'
    },
    component: () => import('../views/banner/List.vue')
  },
  {
    path: '/banner',
    name: 'banner-create',
    meta: {
      requiresLogin: true,
      permissions: 'CanAddBanner'
    },
    component: () => import('../views/banner/Edit.vue')
  },
  {
    path: '/banner/:id',
    name: 'banner-edit',
    meta: {
      requiresLogin: true,
      permissions: 'CanViewBanner'
    },
    component: () => import('../views/banner/Edit.vue')
  },
  {
    path: '/reports/coupon',
    name: 'reports-coupon',
    component: () => import('../views/report/CouponReport.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/packages',
    name: 'package-list',
    component: () => import('../views/package/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewPackage'
    }
  },
  {
    path: '/package/:id',
    name: 'package-edit',
    component: () => import('../views/package/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewPackage'
    }
  },
  {
    path: '/package',
    name: 'package-create',
    component: () => import('../views/package/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddPackage'
    }
  },
  {
    path: '/sub-package/:id',
    name: 'sub-package-edit',
    component: () => import('../views/sub-package/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewPackage'
    }
  },
  {
    path: '/sub-package',
    name: 'sub-package-create',
    component: () => import('../views/sub-package/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddPackage'
    }
  },
  {
    path: '/coupons',
    name: 'coupon-list',
    component: () => import('../views/coupon/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewCoupon'
    }
  },
  {
    path: '/coupon/:id',
    name: 'coupon-edit',
    component: () => import('../views/coupon/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanEditCoupon'
    }
  },
  {
    path: '/coupon',
    name: 'coupon-create',
    component: () => import('../views/coupon/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddCoupon'
    }
  },
  {
    path: '/order-management/order',
    name: 'order',
    component: () => import('../views/order/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/order-management/order/:orderId',
    name: 'order-detail',
    component: () => import('../views/order/OrderDetail.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewPackage'
    }
  },
  {
    path: '/order-management/digital-product',
    name: 'digital-product',
    component: () => import('../views/digital-product/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/user-roles',
    name: 'user-role-list',
    component: () => import('../views/user-role/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewUser'
    }
  },
  {
    path: '/user-role/:id',
    name: 'user-role-edit',
    component: () => import('../views/user-role/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanEditUser'
    }
  },
  {
    path: '/user-role',
    name: 'user-role-create',
    component: () => import('../views/user-role/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddUser'
    }
  },
  {
    path: '/role-permissions',
    name: 'role-permission-list',
    component: () => import('../views/role-permission/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewRolePermission'
    }
  },
  {
    path: '/role-permission/:id',
    name: 'role-permission-edit',
    component: () => import('../views/role-permission/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewRolePermission' // For view and edit
    }
  },
  {
    path: '/role-permission',
    name: 'role-permission-create',
    component: () => import('../views/role-permission/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddRolePermission'
    }
  },
  {
    path: '/organizations',
    name: 'organization-list',
    component: () => import('../views/organization/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewOrganization'
    }
  },
  {
    path: '/organization',
    name: 'organization-create',
    component: () => import('../views/organization/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddOrganization'
    }
  },
  {
    path: '/organization/:id',
    name: 'organization-edit',
    component: () => import('../views/organization/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewOrganization' // For view and edit
    }
  },
  {
    path: '/import-file',
    name: 'import-file',
    component: () => import('../views/import-file/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: ['CanBulkDepartment', 'CanBulkDoctor']
    }
  },
  {
    path: '/hero-banners',
    name: 'hero-banner-list',
    component: () => import('../views/hero-banner/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewHeroBanner'
    }
  },
  {
    path: '/hero-banner',
    name: 'hero-banner-create',
    component: () => import('../views/hero-banner/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddHeroBanner'
    }
  },
  {
    path: '/hero-banner/:id',
    name: 'hero-banner-edit',
    component: () => import('../views/hero-banner/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewHeroBanner' // For view and edit
    }
  },
  {
    path: '/doctors',
    name: 'doctor-list',
    component: () => import('../views/doctor/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDoctor'
    }
  },
  {
    path: '/doctor',
    name: 'doctor-create',
    component: () => import('../views/doctor/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddDoctor'
    }
  },
  {
    path: '/doctor/:id',
    name: 'doctor-edit',
    component: () => import('../views/doctor/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDoctor'
    }
  },
  {
    path: '/doctor-repeater',
    name: 'doctor-repeater-create',
    component: () => import('../views/doctor-content-repeater/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddDoctor'
    }
  },
  {
    path: '/doctor-repeater/:id',
    name: 'doctor-repeater-edit',
    component: () => import('../views/doctor-content-repeater/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDoctor'
    }
  },
  {
    path: '/doctor/:id/schedules',
    name: 'doctor-schedules',
    component: () => import('../views/doctor/Schedules.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDoctor'
    }
  },
  {
    path: '/departments',
    name: 'department-list',
    component: () => import('../views/department/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDepartment'
    }
  },
  {
    path: '/department',
    name: 'department-create',
    component: () => import('../views/department/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddDepartment'
    }
  },
  {
    path: '/department/:id',
    name: 'department-edit',
    component: () => import('../views/department/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDepartment'
    }
  },
  {
    path: '/department/:id/schedules',
    name: 'department-schedules',
    component: () => import('../views/department/Schedules.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDepartment'
    }
  },
  {
    path: '/repeater',
    name: 'repeater-create',
    component: () => import('../views/content-repeater/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddDepartment'
    }
  },
  {
    path: '/repeater/:id',
    name: 'repeater-edit',
    component: () => import('../views/content-repeater/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewDepartment'
    }
  },
  {
    path: '/symptomatic-areas',
    name: 'symptomatic-areas-list',
    component: () => import('../views/symptomatic-areas/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewSymptomatic'
    }
  },
  {
    path: '/symptomatic-area',
    name: 'symptomatic-areas-create',
    component: () => import('../views/symptomatic-areas/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddSymptomatic'
    }
  },
  {
    path: '/symptomatic-area/:id',
    name: 'symptomatic-areas-edit',
    component: () => import('../views/symptomatic-areas/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewSymptomatic'
    }
  },
  {
    path: '/my/profile',
    name: 'my-profile',
    component: () => import('../views/my-profile/Edit.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/notifications',
    name: 'notification-list',
    component: () => import('../views/notification/List.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanViewNotification'
    }
  },
  {
    path: '/notification/create',
    name: 'notification-create',
    component: () => import('../views/notification/Edit.vue'),
    meta: {
      requiresLogin: true,
      permissions: 'CanAddNotification'
    }
  },
  {
    path: '/force-updates',
    name: 'force-update-list',
    component: () => import('../views/force-update/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/force-update/:id',
    name: 'force-update-edit',
    component: () => import('../views/force-update/Edit.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/localize',
    name: 'localize',
    component: () => import('../views/localize/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/term-and-conditions',
    name: 'term-and-conditions',
    component: () => import('../views/term-and-condition/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/term-and-condition/create',
    name: 'term-and-condition-create',
    component: () => import('../views/term-and-condition/Edit.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/feature-setups',
    name: 'feature-setup-list',
    component: () => import('../views/feature-setup/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/feature-setup/:codename',
    name: 'feature-setup-edit',
    component: () => import('../views/feature-setup/Edit.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/qr-codes',
    name: 'qr-code-list',
    component: () => import('../views/qr-code/List.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/qr-code/:id',
    name: 'qr-code-edit',
    component: () => import('../views/qr-code/Edit.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../views/errors/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Route that requires auth, check if already logged in.
// Otherwise, redirect to login page.
// Logged user; only when the password needs a reset, redirect to reset page.
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin)) {
    const loggedIn = store.getters['auth/getIsLoggedIn']

    if (loggedIn) {
      // authentcated user, check when pass-reset is required.
      const changePassword = store.getters['auth/isForceChangePassword']
      if (changePassword && !to.name.match('reset-passw')) {
        return next({name: 'reset-passw'})
      }
    } else {
      // unauthenticated user, take it to /login
      return next({
        name: 'login',
        query: {redirect: to.fullPath}
      })
    }
  }
  if (to.matched.some(record => record.meta.permissions)) {
    const permission = to.matched.find(record => record.meta.permissions)
    if (Array.isArray(permission.meta.permissions)) {
      if (permission && !hasPermissions(permission.meta.permissions)) {
        return next({name: 'not-found'})
      }
    } else {
      if (permission && !hasPermision(permission.meta.permissions)) {
        return next({name: 'not-found'})
      }
    }
  }
  next()
})

export default router
