<template>
  <div>
    <v-row class="mb-3 d-flex align-end">
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-text body-bold align-self-start text-truncate"
        cols="5"
      >
        <span>{{ name }}</span
        ><br />
        <span class="body-regular">{{ subTitle }}</span>
      </v-col>
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-text text-start"
        cols="3"
        style="font-size: 0.75rem;"
      >
        {{ getPrice(price) }}
        <span
          v-if="!isNoDiscount"
          class="disableTextColor--text text-start discount-price pl-2"
        >
          {{ getPrice(discountPrice) }}
        </span>
      </v-col>
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-label sub-body-regular text-center"
        cols="2"
      >
        {{ getQuantityText(quantity) }}
      </v-col>
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-text body-bold text-center"
        cols="2"
      >
        {{ getPrice(totalPrice) }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {discountTypeEnum} from '@/constants/package'
import {convertIntegerToCommaNumber} from '@/helper/helper'

export default {
  name: 'ProductInformation',
  props: {
    name: {type: String},
    quantity: {type: Number},
    price: {type: Number},
    discountPrice: {type: Number},
    discountType: {type: String},
    subTitle: {type: String},
    totalPrice: {type: Number}
  },
  computed: {
    isNoDiscount() {
      return this.discountType === discountTypeEnum.NO_DISCOUNT
    }
  },
  methods: {
    getPrice(value) {
      return `฿ ${convertIntegerToCommaNumber(value)}`
    },
    getQuantityText(quantity) {
      return `x${quantity}`
    }
  }
}
</script>
<style lang="sass" scoped>
.discount-price
  text-decoration: line-through
</style>
