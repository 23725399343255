<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <v-alert-error :show="isSaveError" :close="closeAlert"></v-alert-error>
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>

    <v-alert-confirm
      :show="isDeleteContentRepeater"
      title="Remove Confirmation"
      :subTitle="subTitleDeleteContentRepeater"
      :close="closeDeletePopupContenterRepeater"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDeleteContentRepeater"
      icon=""
    ></v-alert-confirm>
    <v-alert-confirm
      t-data="save-popup"
      :show="isSave"
      subTitle='Want to continue managing content repeater? The department is created successfully. To complete the department setup, you requires to create content repeater into the department, click "Continue" to proceed. Or else complete it later by clicking "Cancel".'
      headline-color="black"
      buttonText="Continue"
      :close="onCreatedCancel"
      :confirm="onContinue"
      confirm-btn-color="info600Color"
      icon=""
    ></v-alert-confirm>
    <v-preview
      v-if="isPreview"
      :show="isPreview"
      :close-preview="closePreview"
      :doctor-object="doctorObject"
    />
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Doctor
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete()"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="disabledPreview"
            :on-remove="showDeletePopup"
            :on-cancel="showCancelPopup"
            :on-preview="onPreview"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Doctor Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="doctorObject.status"
            :disabled="disabledEdit || disableEmptyBranch"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Header Image <span class="danger500Color--text">*</span>
              </p>
              <image-dropzone
                title="Header Image"
                subtitle="Recommended size 176x176px."
                subtitle-font-class="small-bold"
                content-font-class="small"
                class="can-disable-selector"
                :thumbnail="doctorObject.profile"
                :is-error="isHeaderImageError"
                :error-msg="headerImageErrorMessage"
                :disabled="disabledEdit"
                @update="updateImageHeader"
                @remove="removeImageHeader"
              ></image-dropzone>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch <span class='danger500Color--text'>*</span>"
                :isDisabled="disabledEdit"
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                @change="resetDepartmentAndLicenseValidate"
                :isEditMode="isEditMode"
                v-if="!isLoading"
              ></single-selected-branch>

              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Department <span class="danger500Color--text">*</span>
              </p>

              <v-combobox
                class="mb-0"
                :placeholder="disabledEdit ? '' : 'Input Department'"
                v-model="selectedSymptomaticItems"
                @input="handleInputSymptomatics"
                @change="changedSymptomatics"
                multiple
                outlined
                dense
                hide-details
                :items="departmentList"
                item-text="text"
                item-value="value"
                variant="solo"
                :clearable="hasPermEdit()"
                :rules="[validateSymptomatic]"
                :readonly="disabledEdit || disableEmptyBranch"
                :class="{'symptomatic-box-error': symptomaticError}"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeSymptomaticItem(item)"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit"
                    clearable
                  >
                    {{ item.text }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>

              <div
                t-data="error-message"
                class="flex-nowrap"
                v-if="symptomaticError && !disabledEdit && !disableEmptyBranch"
              >
                <p class="edit-error-message sub-body-regular mb-0">
                  {{ symptomaticError }}
                </p>
              </div>

              <div
                t-data="error-message"
                class="flex-nowrap"
                v-else-if="
                  symptomaticError && (disabledEdit || disableEmptyBranch)
                "
              >
                <p
                  class="sub-body-regular mb-0"
                  style="color:rgba(0, 0, 0, 0.38); font-size:12px !important"
                >
                  {{ symptomaticError }}
                </p>
              </div>

              <p
                class="edit-field-label sub-body-bold mt-6"
                t-data="info-label"
              >
                Alt Text <span class="danger500Color--text"></span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                v-model="doctorObject.profile_alt"
                :placeholder="disabledEdit ? '' : 'Enter Alt Text.'"
                @input="resetLicenseValidate"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Alias <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                required="true"
                :rules="[aliasRules.required]"
                v-model="doctorObject.alias"
                :placeholder="disabledEdit ? '' : 'Input Alias'"
                :hide-details="!isAliasError"
                @change="setCheckedAlias"
                @keypress="isNotSpecialChar"
                @paste="pasteData"
              ></v-text-field>
              <v-row
                align="center"
                justify="start"
                class="flex-nowrap"
                t-data="permalink-container"
                no-gutters
              >
                <p class="value-message small mb-0 secondaryTextColor--text">
                  Permalink :
                  <a @click="clickPackageLink">{{ pageLink }}</a>
                  <a></a>
                </p>
              </v-row>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Alias <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                disabled
                required="true"
                :rules="[aliasRules.required]"
                v-model="doctorObject.alias"
                :placeholder="disabledEdit ? '' : 'Input Alias'"
                :hide-details="!isAliasError"
              ></v-text-field>
              <v-row
                align="center"
                justify="start"
                class="flex-nowrap"
                t-data="permalink-container"
                no-gutters
              >
                <p class="value-message small mb-0 secondaryTextColor--text">
                  Permalink :
                  <a @click="clickPackageLinkEn">{{ pageLinkEn }}</a>
                  <a></a>
                </p>
              </v-row>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Title <span class="danger500Color--text">*</span>
              </p>
              <v-select
                :items="prefixObject"
                v-model="doctorObject.prefix_id"
                :disabled="disabledEdit || disableEmptyBranch"
                :menu-props="{bottom: true, offsetY: true}"
                outlined
                dense
                item-text="text"
                item-value="value"
                :rules="TitleRules"
                class="can-disable-selector"
                :placeholder="disabledEdit ? '' : 'Doctor Title'"
                @input="handlePrefixChange"
                :error="1 === 2 ? true : false"
              ></v-select>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                License No <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                v-model="doctorObject.license_no"
                :error="isLicenseError"
                :rules="licenseNoRules"
                :error-messages="licenseErrorMessage"
                :placeholder="disabledEdit ? '' : 'Enter Doctor\'s License No.'"
                @input="resetLicenseValidate"
                @keypress="isNumber"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Tags
              </span>
              <v-combobox
                class="combobox-tag can-disable-selector"
                v-model="selectedTags"
                :placeholder="disabledEdit ? '' : 'Input Title Tags'"
                @input="handleInputTags"
                multiple
                outlined
                dense
                hide-details
                :disabled="disabledEdit || disableEmptyBranch"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeTag(item, 'tags')"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit || disableEmptyBranch"
                  >
                    {{ item }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="6" t-data="info-input">
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Language
              </span>
              <v-combobox
                class="mb-6"
                :placeholder="disabledEdit ? '' : 'Input Language'"
                v-model="selectedLanguageItems"
                @input="handleInputLanguages"
                @change="changedLanguages"
                multiple
                outlined
                dense
                hide-details
                :items="languageList"
                item-text="text"
                item-value="value"
                variant="solo"
                :clearable="hasPermEdit()"
                :readonly="disabledEdit || disableEmptyBranch"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeLanguageItem(item)"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit || disableEmptyBranch"
                    clearable
                  >
                    {{ item.text }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6">
              <h4 class="mb-6 card-title" t-data="info-title">
                Thai
              </h4>
            </v-col>
            <v-col cols="6">
              <h4 class="mb-6 card-title" t-data="info-title">
                English
              </h4>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Doctor Name <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                :rules="nameThRules"
                v-model="doctorObject.contents[0].name"
                :placeholder="disabledEdit ? '' : 'Doctor Name'"
              ></v-text-field>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Doctor Name <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                :rules="nameEnRules"
                v-model="doctorObject.contents[1].name"
                :placeholder="disabledEdit ? '' : 'Doctor Name'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Specialty <span class="danger500Color--text">*</span>
              </p>

              <v-combobox
                class="combobox-tag can-disable-selector"
                v-model="selectedSpecialtyThTags"
                :placeholder="disabledEdit ? '' : 'Input Specialty Tags'"
                @input="handleInputSpecialtyThTags"
                multiple
                outlined
                dense
                hide-details
                :disabled="disabledEdit || disableEmptyBranch"
                :rules="[validateSpecialty]"
                :class="{
                  'symptomatic-box-error': specialtyError
                }"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip wrap-text"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeTag(item, 'specialtyTh')"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit || disableEmptyBranch"
                  >
                    {{ item }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
              <div
                t-data="error-message"
                class="flex-nowrap"
                v-if="specialtyError && !disabledEdit && !disableEmptyBranch"
              >
                <p class="edit-error-message sub-body-regular mb-0">
                  {{ specialtyError }}
                </p>
              </div>
              <div
                t-data="error-message"
                class="flex-nowrap"
                v-else-if="
                  specialtyError && (disabledEdit || disableEmptyBranch)
                "
              >
                <p
                  class="sub-body-regular mb-0"
                  style="color:rgba(0, 0, 0, 0.38); font-size:12px !important"
                >
                  {{ specialtyError }}
                </p>
              </div>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Specialty <span class="danger500Color--text">*</span>
              </p>

              <v-combobox
                class="combobox-tag can-disable-selector"
                v-model="selectedSpecialtyEnTags"
                :placeholder="disabledEdit ? '' : 'Input Specialty Tags'"
                @input="handleInputSpecialtyEnTags"
                multiple
                outlined
                dense
                hide-details
                :disabled="disabledEdit || disableEmptyBranch"
                :rules="[validateSpecialtyEn]"
                :class="{
                  'symptomatic-box-error': specialtyError
                }"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip wrap-text"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeTag(item, 'specialtyEn')"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit || disableEmptyBranch"
                  >
                    {{ item }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
              <div
                t-data="error-message"
                class="flex-nowrap"
                v-if="specialtyErrorEn && !disabledEdit && !disableEmptyBranch"
              >
                <p class="edit-error-message sub-body-regular mb-0">
                  {{ specialtyErrorEn }}
                </p>
              </div>
              <div
                t-data="error-message"
                class="flex-nowrap"
                v-else-if="
                  specialtyErrorEn && (disabledEdit || disableEmptyBranch)
                "
              >
                <p
                  class="sub-body-regular mb-0"
                  style="color:rgba(0, 0, 0, 0.38); font-size:12px !important"
                >
                  {{ specialtyError }}
                </p>
              </div>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 mb-6 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Education/Experience
              </p>
              <quill-editor
                ref="quillTh"
                v-model="doctorObject.contents[0].description"
                :options="editorOption"
              ></quill-editor
            ></v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Education/Experience
              </p>

              <quill-editor
                ref="quillEn"
                v-model="doctorObject.contents[1].description"
                :options="editorOption"
              ></quill-editor
            ></v-col>
          </v-row>
          <meta-content
            :key="`meta-content-${componentKey}`"
            :disabledEdit="disabledEdit || disableEmptyBranch"
          ></meta-content>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card class="mt-6">
      <v-alert
        v-if="!hasPermEdit()"
        class="warning800Color--text body-regular px-2 py-1 mb-0"
        color="warning300Color"
        t-data="warning-alert"
        dense
        no-gutters
      >
        <div class="d-flex align-center justify-start">
          <v-img
            class="mr-2 warning600Color--text"
            max-height="24px"
            max-width="24px"
            src="/images/icons/warning-yellow-icon.png"
          ></v-img>
          <div style="line-height: 28px;">
            You cannot edit information because you do not have permission to
            edit doctor.
          </div>
        </div>
      </v-alert>
      <v-row class="align-center mb-4 mx-4 pt-6" style="margin-left: 1px">
        <h6 class="pageHeadline" t-data="page-headline">Content Repeater</h6>
        <v-spacer></v-spacer>
        <v-btn
          v-if="hasPermEdit()"
          id="add-btn"
          large
          color="primary600Color"
          class="white-text-button button-bold"
          t-data="add-btn"
          :disabled="!isEditMode"
          @click="addRepeater"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>ADD REPEATER
        </v-btn>
      </v-row>
      <v-data-table
        class="elevation-1"
        :loading="isLoadingContentRepeater"
        :items="contentRepeaterList"
        :headers="contentRepeaterHeaders"
        no-data-text="Please complete creating the Repeater."
      >
        <template v-slot:item.content="{item}" style="width: 840px">
          <tooltip-title :contents="item.contents">
            <template v-slot:content>
              <v-list-item-content
                class="d-inline-block text-truncate"
                style="width: 840px"
              >
                <v-list-item-title>
                  {{
                    item.contents[0].code === 'th'
                      ? item.contents[0].title
                      : item.contents[1].title
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    item.contents[0].code === 'en'
                      ? item.contents[0].title
                      : item.contents[1].title
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </tooltip-title>
        </template>

        <template v-slot:item.action="{item}">
          <div>
            <v-icon
              t-data="edit-action"
              class="px-2 action-icon"
              @click="rowClick(item)"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              v-if="hasPermEdit()"
              t-data="delete-action"
              class="px-2 action-icon"
              @click="showContentRepeaterDeletePopup(item.id)"
              >mdi-trash-can-outline</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
      <created-detail v-if="isEditMode" :data-object="doctorObject" />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete()"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="disabledPreview"
        :on-remove="showDeletePopup"
        :on-cancel="showCancelPopup"
        :on-preview="onPreview"
        :on-save="onSave"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {mapState, mapActions} from 'vuex'
// import moment from 'moment'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import moment from 'moment'
import Preview from '../../components/doctor/Preview'
import CreatedDetail from '../../components/CreatedDetail'
import ImageUploader from 'quill-image-uploader'
import MyLink from '../../constants/quill-link'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import ImageDropzone from '@/components/ImageDropzone'
import MetaContent from '@/components/doctor/MetaContent'
import SnackBar from '@/components/SnackBar'
import TooltipTitle from '@/components/TooltipTitle'

Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)
import {
  isUrl,
  convertFileToBase64,
  convertSelectedData,
  hasPermissionsByOrgs,
  isAliasAllow,
  isEnglishAlphabet,
  isThaiAlphabet,
  snackbarSuccessContent,
  snackbarFailContent
} from '../../helper/helper'
import {actionEnum} from '@/constants/constants'
import AlertConfirm from '@/components/AlertConfirm'
import EditButtons from '@/components/EditButtons'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-alert-error': AlertError,
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    CreatedDetail,
    EditButtons,
    ImageDropzone,
    SingleSelectedBranch,
    quillEditor,
    MetaContent,
    SnackBar,
    TooltipTitle
  },
  name: 'index',
  data() {
    return {
      isSave: false,
      snackTitle: '',
      pageName: 'Content Repeater',
      isCreateSuccess: false,
      isCreateFail: false,
      subTitleDeleteContentRepeater:
        'Are you sure you want to remove content repeater? This action will remove content in both Thai and English.',
      contentRepeaterHeaders: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: '',
          width: 10
        },
        {
          text: 'Content Repeater Title',
          align: 'left',
          sortable: false,
          value: 'content',
          width: 840
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
          width: 100
        }
      ],
      selectedSymptomaticItems: [],
      selectedLanguageItems: [],
      symptomaticError: '',
      specialtyError: '',
      specialtyErrorEn: '',
      isAliasError: true,
      aliasRules: {
        required: value => {
          if (value) {
            this.isAliasError = false
            return true
          }
          this.isAliasError = true
          return 'Alias is required'
        }
      },
      isChanged: false,
      selectedTags: [],
      selectedSpecialtyEnTags: [],
      selectedSpecialtyThTags: [],
      componentKey: 0,
      prefix_id: '',
      datePicker: false,
      isEditMode: false,
      valid: true,
      isLoad: false,
      isCancel: false,
      isPreview: false,
      title_th: '',
      title_en: '',
      image_th: '',
      image_en: '',
      isImageEngError: false,
      imageEngErrorMessage: '',
      isLicenseError: false,
      licenseErrorMessage: '',
      description_th: '',
      description_en: '',
      categoryId: '',
      statusId: '',
      isSaveError: false,
      isError: false,
      isSuccess: false,
      imageMaxSize: 3,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      },
      html: '',
      statusItems: [
        {text: 'Active', value: 0},
        {text: 'Inactive', value: 1},
        {text: 'Active Date', value: 2}
      ],
      TitleRules: [v => !!v || 'Title is required'],
      nameThRules: [v => !!v || 'Doctor Name is required'],
      nameEnRules: [v => !!v || 'Doctor Name is required'],
      categoryRules: [v => !!v || 'Category is required'],
      hospitalRules: [v => !!v || 'Branch is required'],
      licenseNoRules: [v => !!v || 'License no. is required'],
      activeDate: [v => !!v || 'Active date is required'],
      statusRules: [v => v != '' || 'Status is required'],
      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Doctors',
          disabled: false,
          href: '/doctor-list'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ],
      date: moment(new Date())
        .add(1, 'days')
        .toISOString()
        .substr(0, 10),
      subTitle:
        'Are you sure you want to remove this Doctor? This action will remove content in both Thai and English.'
    }
  },
  mounted() {
    this.setPrefix()
    this.fetchLanguageList()
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }

    this.isAliasError = false
    if (this.disabledEdit || this.disableEmptyBranch) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  computed: {
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    publicDate: {
      get() {
        return this.checkActiveDate(
          this.doctorObject.public_date,
          this.doctorObject.status
        )
      },
      set(date) {
        this.doctorObject.public_date = moment(date)
        this.datePicker = false
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Doctors', links: true, to: '/doctors'},
        {text: this.textMode, links: false}
      ]
    },
    categoryItems: function() {
      let categories = this.$store.state.doctor.category.filter(function(obj) {
        return obj.text != 'All'
      })
      return categories
    },
    isActiveDate: function() {
      return this.doctorObject.status === '2' ? true : false
    },
    cachImage: function(image) {
      return `${image}?${new Date().getTime()}`
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.doctorObject.org_codename !== null) {
        selectedBranch = this.doctorObject.org_codename
      }
      return selectedBranch
    },
    disabledPreview() {
      return (
        this.doctorObject.prefix_id === '' ||
        this.doctorObject.prefix_id === null ||
        this.doctorObject.department_id === '' ||
        this.doctorObject.department_id === null ||
        this.doctorObject.org_codename === null ||
        this.doctorObject.org_codename === '' ||
        this.doctorObject.profile === null ||
        this.doctorObject.profile === '' ||
        this.doctorObject.license_no === null ||
        this.doctorObject.license_no === '' ||
        this.doctorObject.contents[0].name === null ||
        this.doctorObject.contents[0].name === '' ||
        this.doctorObject.contents[1].name === null ||
        this.doctorObject.contents[1].name === '' ||
        this.doctorObject.specialty_id === ''
      )
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditDoctor', [this.doctorObject.org_codename])
      )
    },
    disableEmptyBranch() {
      if (
        !this.doctorObject.org_codename &&
        this.doctorObject.org_codename !== null
      ) {
        return true
      }
      return false
    },
    ...mapState('doctor', [
      'doctorObject',
      'isLoading',
      'orgObject',
      'departmentList',
      'prefixObject',
      'isDuplicateLicenseNo',
      'isHeaderImageError',
      'headerImageErrorMessage',
      'isDelete',
      'specialtyListTh',
      'specialtyListEn',
      'languageList',
      'pageLink',
      'pageLinkEn'
    ]),
    ...mapState('auth', ['user']),
    ...mapState('department', ['departmentObject']),
    ...mapState('doctorContentRepeater', {
      contentRepeaterList: 'contentRepeaterList',
      isLoadingContentRepeater: 'isLoading',
      isDeleteContentRepeater: 'isDelete'
    })
  },
  // destroyed() {
  //   this.isSuccess = true
  //   this.resetState()
  // },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
    this.resetContentRepeater()
  },
  // beforeMount() {
  //   this.resetState()
  // },
  async created() {
    this.fetchDetails()
  },
  watch: {
    $route: function() {
      this.fetchDetails()
    },
    disableEmptyBranch() {
      if (this.disabledEdit || this.disableEmptyBranch) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    },
    disabledEdit() {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    }
  },
  methods: {
    async fetchDetails() {
      this.isEditMode = !!this.$route.params.id
      this.setEditMode(this.isEditMode)
      await this.fetchCategory()
      if (this.isEditMode) {
        await this.setDoctorId(this.$route.params.id)
        await this.getContentRepeaters(this.$route.params.id)
        // scroll to top
        window.scrollTo(0, 0)
        await this.setDepartment(true)
        this.setSelectedTag()
        if (this.doctorObject.alias && this.doctorObject.id)
          this.setAlias(this.doctorObject.alias)
      }
      const isViewOnly =
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditDoctor', [this.doctorObject.org_codename])
      await this.setOrgInfo(isViewOnly)
    },
    onCreatedCancel() {
      this.$router.push({
        name: 'doctor-list',
        query: {isCreateSuccess: true, snackTitle: 'created'}
      })
    },
    onContinue() {
      this.isSave = false
      this.$router.push({
        name: 'doctor-edit',
        params: {id: this.doctorObject.id}
      })
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    async confirmDeleteContentRepeater() {
      const deleteStatus = await this.deleteContentRepeater()
      await this.getContentRepeaters(this.doctorObject.id)
      if (deleteStatus) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    rowClick(repeater) {
      this.$router.push({
        name: 'doctor-repeater-edit',
        params: {id: repeater.id}
      })
    },
    addRepeater() {
      this.setDoctorIdInContentRepeater(this.doctorObject.id)
      this.$router.push({name: 'doctor-repeater-create'})
    },
    showContentRepeaterDeletePopup(id) {
      this.setContentRepeaterDeleteId(id)
      this.setContentRepeaterDeletePopup(true)
    },
    closeDeletePopupContenterRepeater() {
      this.setContentRepeaterDeletePopup(false)
    },
    setSelectedTag() {
      this.selectedTags = this.doctorObject.tags.map(t => t.value)
      this.selectedLanguageItems = this.doctorObject.languages.map(t => ({
        value: t.id,
        text: t.name
      }))
      this.doctorObject.language_ids = this.doctorObject.languages.map(t => ({
        language_id: t.id
      }))
      this.selectedSpecialtyThTags = this.doctorObject.specialty_th_tags.map(
        t => t.value
      )
      this.selectedSpecialtyEnTags = this.doctorObject.specialty_en_tags.map(
        t => t.value
      )
      this.doctorObject.department_ids = this.doctorObject.departments.map(
        t => ({
          department_id: t.department_id
        })
      )
      this.selectedSymptomaticItems = this.doctorObject.departments.map(t => {
        const department = this.departmentList.find(
          x => x.value === t.department_id
        )
        const departmentName = department !== undefined ? department.text : ''
        return {
          value: t.department_id,
          text: departmentName
        }
      })
    },
    isNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    assignSymptomatics(values) {
      this.doctorObject.department_ids = values.map(v => ({
        department_id: v.value
      }))
    },
    assignLanguages(values) {
      this.doctorObject.language_ids = values.map(v => ({
        language_id: v.value
      }))
    },
    removeSymptomaticItem(item) {
      this.selectedSymptomaticItems.splice(
        this.selectedSymptomaticItems.indexOf(item),
        1
      )
      this.assignSymptomatics(this.selectedSymptomaticItems)
    },
    removeLanguageItem(item) {
      this.selectedLanguageItems.splice(
        this.selectedLanguageItems.indexOf(item),
        1
      )
      this.assignLanguages(this.selectedLanguageItems)
    },
    handleInputSymptomatics(values) {
      this.assignSymptomatics(values)
    },
    handleInputLanguages(values) {
      this.assignLanguages(values)
    },
    changedSymptomatics(newItems) {
      this.selectedSymptomaticItems = newItems.filter(item =>
        this.departmentList.some(
          existingItem => existingItem.value === item.value
        )
      )
      if (this.selectedSymptomaticItems.length == 0) {
        this.symptomaticError = 'Department is required'
      } else {
        this.symptomaticError = ''
      }
    },
    changedLanguages(newItems) {
      this.selectedLanguageItems = newItems.filter(item =>
        this.languageList.some(
          existingItem => existingItem.value === item.value
        )
      )
    },
    validateSymptomatic() {
      if (this.disabledEdit || this.disableEmptyBranch) return true
      return this.symptomaticError === '' || this.symptomaticError
    },
    validateSpecialty() {
      return this.specialtyError === '' || this.specialtyError
    },
    validateSpecialtyEn() {
      return this.specialtyErrorEn === '' || this.specialtyErrorEn
    },
    clickPackageLink() {
      window.open(this.pageLink, '_blank')
    },
    clickPackageLinkEn() {
      window.open(this.pageLinkEn, '_blank')
    },
    setCheckedAlias(val) {
      this.setAlias(val)
    },
    isNotSpecialChar(event) {
      if (!this.validAlias(event.key)) {
        event.preventDefault()
      }
    },
    pasteData(event) {
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        'text'
      )
      if (!this.validAlias(pastedText)) {
        event.preventDefault()
      }
    },
    validAlias(text) {
      if (
        !isEnglishAlphabet(text) &&
        !isThaiAlphabet(text) &&
        !isAliasAllow(text)
      ) {
        return false
      } else {
        return true
      }
    },
    removeTag(item, type) {
      if (type == 'tags') {
        this.selectedTags.splice(this.selectedTags.indexOf(item), 1)
        this.assignTags(this.selectedTags, 'tags')
      } else if (type == 'specialtyTh') {
        this.selectedSpecialtyThTags.splice(
          this.selectedSpecialtyThTags.indexOf(item),
          1
        )
        this.assignTags(this.selectedSpecialtyThTags, 'specialtyTh')
      } else if (type == 'specialtyEn') {
        this.selectedSpecialtyEnTags.splice(
          this.selectedSpecialtyEnTags.indexOf(item),
          1
        )
        this.assignTags(this.selectedSpecialtyEnTags, 'specialtyEn')
      }
    },
    assignTags(values, type) {
      if (type == 'tags') {
        this.doctorObject.tags = values.map(v => ({value: v}))
      } else if (type == 'specialtyTh') {
        this.doctorObject.specialty_th_tags = values.map(v => ({value: v}))
      } else if (type == 'specialtyEn') {
        this.doctorObject.specialty_en_tags = values.map(v => ({value: v}))
      }
    },
    handleInputTags(values) {
      this.assignTags(values, 'tags')
    },
    handleInputSpecialtyThTags(values) {
      this.assignTags(values, 'specialtyTh')

      if (this.selectedSpecialtyThTags.length == 0) {
        this.specialtyError = 'Specialty is required'
      } else {
        this.specialtyError = ''
      }
    },
    handleInputSpecialtyEnTags(values) {
      this.assignTags(values, 'specialtyEn')

      if (this.selectedSpecialtyEnTags.length == 0) {
        this.specialtyErrorEn = 'Specialty is required'
      } else {
        this.specialtyErrorEn = ''
      }
    },
    disableQuill() {
      this.$refs.quillTh.quill.enable(false)
      this.$refs.quillEn.quill.enable(false)
      this.$refs.quillTh.quill.root.dataset.placeholder = ''
      this.$refs.quillEn.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillTh.quill.enable(true)
      this.$refs.quillEn.quill.enable(true)
      this.$refs.quillTh.quill.root.dataset.placeholder = 'Insert text here ...'
      this.$refs.quillEn.quill.root.dataset.placeholder = 'Insert text here ...'
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.$router.push({
          name: 'doctor-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    resetLicenseValidate() {
      this.setIsDuplicateLicenseNo(false)
      this.licenseErrorMessage = ''
      this.isLicenseError = false
    },
    resetDepartmentAndLicenseValidate() {
      this.doctorObject.department_id = ''
      this.resetLicenseValidate()
    },
    async changedSelectedBranch(value) {
      if (value.length > 0) {
        this.doctorObject.org_codename = value[0]
        this.setFilterHospital(this.doctorObject.org_codename)
        await this.setDepartment(true)

        if (
          this.doctorObject.department_ids &&
          this.doctorObject.department_ids.length > 0
        ) {
          this.selectedSymptomaticItems = this.departmentList.filter(item =>
            this.doctorObject.department_ids.includes(item.value)
          )
        }

        if (this.doctorObject.alias) {
          const shouldSetAlias = this.isEditMode ? this.doctorObject.id : true
          if (shouldSetAlias) {
            this.setAlias(this.doctorObject.alias)
          }
        }
      }
    },
    hasPermEdit() {
      return hasPermissionsByOrgs('CanEditDoctor', [
        this.doctorObject.org_codename
      ])
    },
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteDoctor', [
        this.doctorObject.org_codename
      ])
    },
    selectDepartment() {
      this.setFilterDepartment(this.doctorObject.department_id)
    },
    publicDateChange(date) {
      this.doctorObject.public_date = date
      this.datePicker = false
    },
    checkActiveDate(date, status) {
      if (date && status === 2) {
        return moment(date)
          .add(1, 'days')
          .toISOString()
          .substr(0, 10)
      } else
        return moment(new Date())
          .add(1, 'days')
          .toISOString()
          .substr(0, 10)
    },
    onEditorThaiChange({html}) {
      this.description_th = html
    },
    onEditorEngChange({html}) {
      this.description_en = html
    },
    handleTitleThaiChange(event) {
      this.title_th = event
    },
    handleTitleEngChange(event) {
      this.title_en = event
    },
    handleCategoryChange(event) {
      this.doctorObject.category = event
    },
    handlePrefixChange(event) {
      var data = this.prefixObject.find(o => o.value === event)
      this.doctorObject.contents[0].prefix_name = data.text
      this.doctorObject.contents[1].prefix_name = data.text_en
      this.doctorObject.prefix_id = data.value
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'doctor-list'
      })
    },
    async onPreview() {
      await this.setDepartmentId(this.doctorObject.department_id)
      this.doctorObject.department = this.departmentObject.contents
      this.isPreview = true
    },
    async onSave() {
      if (this.selectedSymptomaticItems.length == 0) {
        this.symptomaticError = 'Please select department'
      }

      if (this.selectedSpecialtyEnTags.length == 0) {
        this.specialtyErrorEn = 'Specialty is required'
      }

      if (this.selectedSpecialtyThTags.length == 0) {
        this.specialtyError = 'Specialty is required'
      }

      if (this.validate()) {
        if (this.isEditMode) {
          this.isSuccess = await this.updatePkg({
            id: this.$route.params.id,
            pkg: this.doctorObject
          })
          if (this.isSuccess) {
            this.licenseErrorMessage = ''
            this.isLicenseError = false
            this.$refs.form.resetValidation()
            this.resetState()
            this.$router.push({
              name: 'doctor-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
            return
          } else {
            if (this.isDuplicateLicenseNo) {
              this.licenseErrorMessage = 'License No. is duplicated'
              this.isLicenseError = true
            } else {
              return (this.isSaveError = true)
            }
          }
        } else {
          const tempAlias = this.doctorObject.alias
          const tempSymptoms = this.selectedSymptomaticItems
          const tempLang = this.selectedLanguageItems
          const tempPageLink = this.pageLink

          this.isSuccess = await this.create(this.doctorObject)
          if (this.isSuccess) {
            this.$refs.form.resetValidation()
            this.licenseErrorMessage = ''
            this.isLicenseError = false
            this.isSave = true
          } else {
            this.doctorObject.alias = tempAlias
            this.selectedSymptomaticItems = tempSymptoms
            this.selectedLanguageItems = tempLang
            this.$store.state.doctor.pageLink = tempPageLink

            if (this.isDuplicateLicenseNo) {
              this.licenseErrorMessage = 'License No. is duplicated'
              this.isLicenseError = true
            } else {
              return (this.isSaveError = true)
            }
          }
        }
      }
    },
    removeImageHeader() {
      this.doctorObject.profile = ''
      this.setHeaderImageError({
        isError: true,
        message: 'Header Image is required'
      })
    },
    updateImageHeader(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.setHeaderImageError({
            isError: true,
            message: 'Only support image file!'
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setHeaderImageError({
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setHeaderImage(file)
          this.setHeaderImageError({isError: false, message: ''})
          return
        }
      } else {
        this.doctorObject.profile = ''
      }
    },
    imageRule(val) {
      if (this.isEditMode) {
        if (isUrl(val)) {
          return true
        } else {
          if (val != '') {
            return true
          }
          return 'Doctor image is required'
        }
      } else {
        if (!val || val.size > 1000000) {
          return 'Doctor image size should be less than 1 MB!'
        }
        if (!val || !String(val.size).includes('image')) {
          return 'Only support image file!'
        } else if (val != '') {
          return true
        }
        return 'Doctor image is required'
      }
    },
    statusRule(value) {
      if (value != undefined) {
        return true
      } else {
        return 'Status is required'
      }
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isSaveError = false
      this.isSuccess = false
    },
    imageHeaderRules(val) {
      if (this.isEditMode) {
        this.setHeaderImageError({isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setHeaderImageError({
          isError: true,
          message: 'Header Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setHeaderImageError({
            isError: true,
            message: 'Header Image is required'
          })
          return
        }
        this.setHeaderImageError({isError: false, message: ''})
        return
      }
    },
    validate() {
      this.imageHeaderRules(this.doctorObject.profile)
      if (this.$refs.form.validate() && !this.isHeaderImageError) {
        return true
      } else {
        return false
      }
    },
    syncSelectedData(data) {
      this.doctorObject.orgs = convertSelectedData(
        data,
        this.orgObject,
        this.doctorObject.orgs
      )
    },
    ...mapActions('doctor', [
      'auth',
      'create',
      'deletePkg',
      'fetchCategory',
      'fetchSpecialty',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setDepartment',
      'setDoctorId',
      'setEditMode',
      'setFilterDepartment',
      'setFilterHospital',
      'setHeaderImage',
      'setHeaderImageError',
      'setIsDuplicateLicenseNo',
      'setOrgInfo',
      'setPrefix',
      'setStyleImage',
      'update',
      'updatePkg',
      'upload',
      'fetchLanguageList',
      'setAlias'
    ]),
    ...mapActions('department', ['setDepartmentId']),
    ...mapActions('doctorContentRepeater', {
      deleteContentRepeater: 'deleteContentRepeater',
      getContentRepeaters: 'getContentRepeaters',
      setDoctorIdInContentRepeater: 'setDoctorId',
      setContentRepeaterDeleteId: 'setDeleteId',
      setContentRepeaterDeletePopup: 'setDeletePopup',
      resetContentRepeater: 'resetList'
    })
  }
}
</script>

<style scoped>
.symptomatic-box-error {
  color: #ff5252 !important;
}
.v-input--is-readonly {
  background: #ecf0f3 !important;
}
.flex-nowrap p {
  margin-left: 12px;
}
.edit-error-message {
  font-size: 12px !important;
}
.wrap-text {
  word-wrap: break-word; /* For older browsers */
  overflow-wrap: break-word !important; /* Standard property for wrapping long words */
  white-space: normal !important; /* Allows text to wrap to the next line */
  word-break: break-all !important; /* Forces breaking within words if necessary */
  height: auto !important;
  min-height: 32px !important;
}
</style>
