<template>
  <v-layout fluid v-resize="onResize">
    <v-data-table
      :loading="isLoading"
      :headers="
        hasPermsByOrgs(orgList.selected.map(x => x.codename))
          ? table.headers
          : table.viewOnlyHeaders
      "
      :items="sortableList"
      :sort-desc="true"
      :must-sort="false"
      class="elevation-1 custom-table"
      fixed-header
      style="width: 100%"
      :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
      hide-default-footer
    >
      <template v-if="sortableTotal <= 0" v-slot:no-data>
        No data available
      </template>
      <template v-if="sortableTotal > 0" v-slot:body>
        <draggable
          v-model="draggableList"
          tag="tbody"
          handle=".handle"
          @start="dragStart"
          @end="dragEnd"
          :disabled="!hasPermsByOrgs(orgList.selected.map(x => x.codename))"
        >
          <tr v-for="(item, index) in draggableList" :key="item.id">
            <td v-if="hasPermEditByOrgs(item.orgs)">
              <v-img
                v-if="hasPermEditByOrgs(item.orgs)"
                class="handle"
                aspect-ratio="1"
                max-height="24"
                max-width="24"
                src="/images/grip-line-icon.png"
              ></v-img>
            </td>
            <td v-else></td>
            <td>
              {{ index + 1 }}
            </td>
            <td class="py-4">
              <v-img
                t-data="banner-img"
                max-width="86"
                max-height="58"
                height="58"
                :src="getThumbnailByLang(item.contents, 'th')"
              ></v-img>
            </td>
            <td>
              <tooltip-title :contents="item.contents">
                <template v-slot:content>
                  <v-list-item-content
                    class="d-inline-block text-truncate"
                    style="width: 180px"
                  >
                    <v-list-item-title>
                      {{ getContentByLang(item.contents, 'th') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ getContentByLang(item.contents, 'en') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </tooltip-title>
            </td>
            <td>
              {{
                !!item.period_start_date ? dateFmt(item.period_start_date) : ''
              }}
            </td>
            <td>
              {{ !!item.period_end_date ? dateFmt(item.period_end_date) : '' }}
            </td>
            <td>
              <div class="d-flex justify-center">
                <publish-status-chip :is-publish="item.is_publish" />
              </div>
            </td>
            <td>
              <v-switch
                t-data="status-switch"
                color="primary200Color"
                v-model="item.status"
                :false-value="inActiveStatus"
                :true-value="activeStatus"
                :disabled="!hasPermEditByOrgs(item.orgs)"
                @change="event => handleSwitchStatusChange(event, item.id)"
                class="mb-0 pb-0 custom-switch"
              ></v-switch>
            </td>
            <td v-if="hasPermEditByOrgs(item.orgs)">
              <v-icon t-data="sort-action" class="px-2 action-icon" @click="actionEdit(item)"
                >mdi-format-list-numbered</v-icon
              >
            </td>
            <td v-else></td>
          </tr>
        </draggable>
      </template>
      <template v-slot:footer
        ><div class="v-data-footer" style="height: 58px"></div>
      </template>
    </v-data-table>
  </v-layout>
</template>
<script>
import PublishStatusChip from '@/components/PublishStatusChip'
import TooltipTitle from '@/components/TooltipTitle'
import {mapActions, mapState} from 'vuex'
import {bannerStatusEnum} from '@/constants/banner'
import {convertDate, hasPermissionsByOrgs} from '@/helper/helper'

import draggable from 'vuedraggable'

export default {
  name: 'SortableTable',
  components: {
    TooltipTitle,
    draggable,
    PublishStatusChip
  },
  props: {},
  data() {
    return {
      heightOptions: {
        header: 372,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      activeStatus: bannerStatusEnum.ACTIVE,
      inActiveStatus: bannerStatusEnum.INACTIVE,
      table: {
        dragging: false,
        headers: [
          {
            text: '',
            align: 'left',
            sortable: false,
            value: 'order',
            width: 60
          },
          {
            text: 'No.',
            align: 'left',
            sortable: false,
            value: 'id',
            width: 34
          },
          {
            text: 'Image',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 130
          },
          {
            text: 'Title',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 165
          },
          {
            text: 'Publish Start Date',
            align: 'left',
            value: 'period_start_date',
            sortable: false,
            width: 160
          },
          {
            text: 'Publish End Date',
            align: 'left',
            value: 'period_end_date',
            sortable: false,
            width: 157
          },
          {
            text: 'Publish Status',
            align: 'center',
            value: 'is_publish',
            sortable: false,
            width: 135
          },
          {
            text: 'Status',
            align: 'center',
            sortable: false,
            value: 'status',
            width: 65
          },
          {
            text: 'Actions',
            align: 'left',
            sortable: false,
            value: 'actions',
            width: 80
          }
        ],
        viewOnlyHeaders: [
          {
            text: 'No.',
            align: 'left',
            sortable: false,
            value: 'id',
            width: 34
          },
          {
            text: 'Image',
            align: 'left',
            sortable: false,
            value: 'contents',
            width: 130
          },
          {
            text: 'Title',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 175
          },
          {
            text: 'Publish Start Date',
            align: 'left',
            value: 'period_start_date',
            sortable: false,
            width: 160
          },
          {
            text: 'Publish End Date',
            align: 'left',
            value: 'period_end_date',
            sortable: false,
            width: 157
          },
          {
            text: 'Publish Status',
            align: 'center',
            value: 'is_publish',
            sortable: false,
            width: 145
          },
          {
            text: 'Status',
            align: 'center',
            sortable: false,
            value: 'status',
            width: 65
          }
        ]
      }
    }
  },
  computed: {
    draggableList: {
      get() {
        return this.sortableList
      },
      set(val) {
        this.reorderBannersList(val)
      }
    },
    ...mapState('banner', [
      'sortableList',
      'isLoading',
      'orgList',
      'sortableTotal'
    ])
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    dragStart() {
      this.table.dragging = true
    },
    dragEnd() {
      this.table.dragging = false
    },
    hasPermEditByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanEditBanner', codenames)
    },
    hasPermsByOrgs: codenames =>
      hasPermissionsByOrgs('CanEditBanner', codenames),
    dateFmt: date => convertDate(date),
    async handleSwitchStatusChange(event, id) {
      const isSuccess = await this.changeStatus({status: event, id: id})
      if (isSuccess) {
        await this.fetchSortableList()
        this.$emit('updateSuccess')
      } else {
        this.$emit('updateFail')
      }
    },
    getContentByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0].title
      }
      return ''
    },
    getThumbnailByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0].thumbnail
      }
      return ''
    },
    async actionEdit(item) {
      await this.setEditOrder(item)
    },
    ...mapActions('banner', [
      'changeStatus',
      'fetchSortableList',
      'reorderBannersList',
      'setEditOrder',
    ])
  }
}
</script>
