<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      subTitle="Are you sure you want to delete this?"
      :close="closeDeletePopup"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDelete"
      icon=""
    ></alert-confirm>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Content Repeater
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermEdit()"
            :show-preview="false"
            :on-remove="onRemove"
            :show-save="!disabledEdit"
            :on-cancel="showCancelPopup"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Content Repeater
          </h6>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-card class="border-card">
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h6 class="mb-6 card-title" t-data="thai-info-header">
                    Thai Content Repeater
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="mb-6 card-title" t-data="eng-info-header">
                    Eng Content Repeater
                  </h6>
                </v-col>
                <v-col t-data="checkbox-duplicate">
                  <v-checkbox
                    v-model="duplicateContent"
                    :disabled="disabledEdit"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiContent"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Title Content Repeater
                    <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameThRules"
                    v-model="contentRepeaterObject.contents[0].title"
                    :placeholder="
                      disabledEdit ? '' : 'Input Title Content Repeater'
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Title Content Repeater
                    <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameEnRules"
                    v-model="contentRepeaterObject.contents[1].title"
                    :placeholder="
                      disabledEdit ? '' : 'Input Title Content Repeater'
                    "
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <quill-editor
                    ref="quillTh"
                    v-model="contentRepeaterObject.contents[0].description"
                    :options="editorOption"
                  ></quill-editor
                ></v-col>
                <v-col cols="6" t-data="info-input">
                  <quill-editor
                    ref="quillEn"
                    v-model="contentRepeaterObject.contents[1].description"
                    :options="editorOption"
                  ></quill-editor
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ImageUploader from 'quill-image-uploader'
import MyLink from '@/constants/quill-link'
import {quillEditor, Quill} from 'vue-quill-editor'
Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)
import {mapState, mapActions} from 'vuex'
import PopupLoading from '@/components/PopupLoading'
import AlertSuccess from '@/components/AlertSuccess'
import {hasPermissionsByOrgs} from '@/helper/helper'
import {convertFileToBase64} from '../../helper/helper'
import AlertConfirm from '@/components/AlertConfirm'
// import CreatedDetail from '@/components/CreatedDetail'
import EditButtons from '@/components/EditButtons'
import {actionEnum} from '@/constants/constants'

export default {
  components: {
    quillEditor,
    'v-alert-success': AlertSuccess,
    'v-popup-loading': PopupLoading,
    AlertConfirm,
    // CreatedDetail,
    EditButtons
  },
  name: 'Edit',
  data() {
    return {
      isEditMode: false,
      isCancel: false,
      duplicateContent: false,
      title_th: '',
      title_en: '',
      description_th: '',
      description_en: '',
      isError: false,
      isSuccess: false,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      },
      nameThRules: [v => !!v || 'Content Repeater is required'],
      nameEnRules: [v => !!v || 'Content Repeater is required']
    }
  },

  computed: {
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs(
          'CanEditDepartment',
          this.departmentObject.org_codename
        )
      )
    },

    textMode() {
      return this.isEditMode ? 'Edit' : 'Add'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Departments', links: true, to: '/departments'},
        {
          text: `Edit Department`,
          links: true,
          to: `/department/${this.contentRepeaterObject.department_id}`
        },
        {text: this.textMode, links: false}
      ]
    },
    ...mapState('department', ['departmentObject']),
    ...mapState('contentRepeater', [
      'contentRepeaterObject',
      'isLoading',
      'isDelete',
      'deleteId'
    ])
  },
  destroyed() {
    this.resetDepartmentState()
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetDepartmentState()
    this.resetState()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)
    if (!this.isEditMode) {
      await this.setDepartmentId(this.contentRepeaterObject.department_id)
    } else {
      await this.setContentRepeater(this.$route.params.id)
    }
    await this.setDepartmentObject(this.contentRepeaterObject.department_id)
    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditDepartment', [
        this.departmentObject.org_codename
      ])
    await this.setOrgInfo(isViewOnly)
  },
  mounted() {
    if (this.disabledEdit) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  watch: {
    disabledEdit() {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    }
  },
  methods: {
    hasPermEdit() {
      return hasPermissionsByOrgs('CanEditDepartment', [
        this.departmentObject.org_codename
      ])
    },
    async onRemove() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    async confirmDelete() {
      const isSuccess = await this.deleteContentRepeater()
      if (isSuccess) {
        this.$router.push({
          name: 'department-edit',
          query: {isCreateSuccess: true, snackTitle: 'removed'},
          params: {id: this.contentRepeaterObject.department_id}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'department-edit',
        params: {id: this.contentRepeaterObject.department_id}
      })
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showFailSnackbar(action) {
      this.snackTitle = action
    },
    disableQuill() {
      this.$refs.quillTh.quill.enable(false)
      this.$refs.quillEn.quill.enable(false)
      this.$refs.quillTh.quill.root.dataset.placeholder = ''
      this.$refs.quillEn.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillTh.quill.enable(true)
      this.$refs.quillEn.quill.enable(true)
      this.$refs.quillTh.quill.root.dataset.placeholder = 'Insert text here ...'
      this.$refs.quillEn.quill.root.dataset.placeholder = 'Insert text here ...'
    },
    async onSave() {
      let status = false
      let snackTitle = 'created'
      if (this.validate()) {
        if (!this.isEditMode) {
          status = await this.create(this.contentRepeaterObject)
        } else {
          snackTitle = 'edited'
          const payload = {
            cpt: this.contentRepeaterObject,
            id: this.$route.params.id
          }
          status = await this.update(payload)
        }
        if (status) {
          this.$router.push({
            name: 'department-edit',
            query: {isCreateSuccess: true, snackTitle: snackTitle},
            params: {id: this.contentRepeaterObject.department_id}
          })
        } else {
          return (this.isError = true)
        }
      }
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    validate() {
      if (this.$refs.form.validate()) {
        return true
      } else {
        return false
      }
    },
    // changeTitleEn(val) {
    //   this.contentRepeaterObject.contents[1].title = val
    // },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.contentRepeaterObject.contents[1].title = this.contentRepeaterObject.contents[0].title
        this.contentRepeaterObject.contents[1].description = this.contentRepeaterObject.contents[0].description
      } else {
        this.contentRepeaterObject.contents[1].title = null
        this.contentRepeaterObject.contents[1].description = ''
      }
    },
    duplicateThaiContentChange() {
      if (this.duplicateContent) {
        this.contentRepeaterObject.contents[1].title = this.contentRepeaterObject.contents[0].title
        this.contentRepeaterObject.contents[1].description = this.contentRepeaterObject.contents[0].description
      }
    },
    ...mapActions('department', {
      resetDepartmentState: 'resetState',
      setDepartmentObject: 'setDepartmentId'
    }),
    ...mapActions('contentRepeater', [
      'create',
      'update',
      'resetState',
      'setEditMode',
      'setDepartmentId',
      'setOrgInfo',
      'setContentRepeater',
      'deleteContentRepeater',
      'setDeletePopup',
      'setDeleteId',
      'upload'
    ])
  }
}
</script>

<style scoped></style>
