<template>
  <v-dialog v-model="show" persistent max-width="550">
    <v-card class="edit-container">
      <v-card-title class="headline" t-data="headline">
        <h4>Quick Filter Department</h4>
      </v-card-title>
      <v-card-text t-data="card-text" class="card-text pb-4">
        <v-autocomplete
          id="search-email"
          v-model="departmentQuickFilter"
          :items="allDepartmentFilter"
          item-text="name"
          :error="errorAlert"
          return-object
          background-color="grayscale"
          prepend-inner-icon="search"
          :search-input.sync="searchInput"
          append-icon=""
          filled
          placeholder="Select Department"
          @change="selectedDepartment"
          solo
          flat
          dense
          single-line
        >
          <template v-slot:selection="data">
            <div>{{ data.item.name }}</div>
          </template>
          <template v-slot:item="data">
            <v-list-item-content class="primaryTextColor--text">{{
              data.item.name
            }}</v-list-item-content>
            <v-list-item-action
              v-if="data.item.disabled"
              class="success600Color--text"
            >
              Added
            </v-list-item-action>
          </template>
        </v-autocomplete>
        <div t-data="error-message" class="flex-nowrap" v-if="errorAlert">
          <p class="edit-error-message sub-body-regular mb-0">
            Add more than the specified number of quick filters.
          </p>
        </div>
        <br />

        <v-alert
          class="alert-container align-self-center"
          t-data="alert-container"
          color="info200Color"
          dense
          icon="mdi-information"
        >
          You can add up to 3 quick filters.</v-alert
        >
        <v-layout fluid v-resize="onResize">
          <v-data-table
            :headers="headers"
            :items="quickFilters"
            :sort-desc="true"
            :must-sort="false"
            class="custom-table"
            fixed-header
            style="width: 100%"
            :height="
              windowSize.y - heightOptions.header - heightOptions.tableFooter
            "
            :hide-default-footer="true"
          >
            <template v-slot:item.name="{item}">
              <span>{{ item.name }}</span>
            </template>
            <template v-slot:item.action="{item}">
              <v-icon
                t-data="delete-action"
                class="px-2"
                color="secondaryText"
                @click="deleteDepartment(item)"
                small
                >mdi-trash-can-outline</v-icon
              >
            </template>
          </v-data-table>
        </v-layout>
      </v-card-text>
      <v-card-actions t-data="card-action">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-button button-bold"
          t-data="cancel-btn"
          color="info600Color"
          text
          @click="cancelPopup"
        >
          Cancel</v-btn
        >
        <v-btn
          class="confirm-button button-bold"
          t-data="confirm-btn"
          color="info600Color"
          text
          @click="confirmPopup"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  props: {
    show: {type: Boolean},
    close: {type: Function},
    confirm: {type: Function}
  },
  data: () => ({
    errorAlert: false,
    departmentQuickFilter: [],
    heightOptions: {
      header: 400,
      tableFooter: 20
    },
    windowSize: {
      x: 0,
      y: 0
    },
    headers: [
      {text: 'Department', value: 'name', sortable: false, width: '45%'},
      {text: 'Action', value: 'action', sortable: false, width: '10%'}
    ],
    searchInput: '',
    departmentsInQuickFilter: []
  }),
  computed: {
    ...mapState('department', ['allDepartmentFilter', 'quickFilters'])
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    selectedDepartment(obj) {
      if (this.quickFilters.length > 2) {
        this.errorAlert = true
      } else {
        this.departmentsInQuickFilter = [
          {id: obj.id, ...obj, isNewUser: true}
        ].concat(this.quickFilters)
        this.setQuickFilterDepartments({
          depts: this.allDepartmentFilter,
          deptsInQuickFilter: this.departmentsInQuickFilter
        })
      }
      // clear data in input
      this.$nextTick(() => {
        this.searchInput = ''
        this.departmentQuickFilter = null
      })
    },
    deleteDepartment(department) {
      this.departmentsInQuickFilter = this.quickFilters.filter(
        obj => obj.id !== department.id
      )
      this.setQuickFilterDepartments({
        depts: this.allDepartmentFilter,
        deptsInQuickFilter: this.departmentsInQuickFilter
      })
      if (this.quickFilters.length < 3) {
        this.errorAlert = false
      }
    },
    cancelPopup() {
      this.resetDepeartmentsInHighlight()
      this.errorAlert = false
      this.$emit('cancel')
    },
    confirmPopup() {
      this.errorAlert = false
      this.$emit('confirm')
    },
    resetDepeartmentsInHighlight() {
      this.departmentsInQuickFilter = this.allDepartmentFilter.filter(
        obj => obj.is_quick_filter == 1
      )
      this.setQuickFilterDepartments({
        depts: this.allDepartmentFilter,
        deptsInQuickFilter: this.departmentsInQuickFilter
      })
    },
    ...mapActions('department', ['setQuickFilterDepartments'])
  }
}
</script>

<style lang="scss" scoped>
.card-text {
  border-bottom: var(--divider-color) 1px solid;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  width: 400px !important;
}

.v-autocomplete.error--text {
  border: 1px solid red !important;
  height: 42px;
}

.v-autocomplete {
  height: 42px;
}
.alert-container {
  color: var(--info-800-color) !important;
  font-size: 0.875rem;
  padding: 6px 10px;
}
.alert-container > .v-alert__wrapper > .theme--light.v-icon {
  color: var(--info-500-color) !important;
  font-size: 1.25rem;
  margin-right: 10px;
  align-self: center !important;
}
</style>
