var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0 px-2 mb-6",attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"t-data":"info-input"}},[_c('p',{staticClass:"edit-field-label sub-body-bold",attrs:{"t-data":"info-label"}},[_vm._v(" Start Time "),_c('span',{staticClass:"danger500Color--text"})]),_c('v-select',{staticClass:"can-disable-selector",attrs:{"placeholder":"Select Start Time","items":_vm.timeSlots,"outlined":"","required":"","color":"primary500Color","dense":"","item-text":"text","item-value":"value","hide-details":true,"clearable":_vm.timeslotObj.is_final ? true : false,"append-icon":"mdi-clock-outline","disabled":!_vm.hasPermEdit},model:{value:(_vm.timeslotObj.start_time),callback:function ($$v) {_vm.$set(_vm.timeslotObj, "start_time", $$v)},expression:"timeslotObj.start_time"}}),_c('p',{staticClass:"value-message edit-error-message small mb-0",staticStyle:{"visibility":"hidden"}},[_vm._v(" Start time must be less than start time ")])],1),_c('v-col',{attrs:{"t-data":"info-input"}},[_c('span',{staticClass:"edit-field-label sub-body-bold",attrs:{"t-data":"info-label"}},[_vm._v(" End Time ")]),_c('v-select',{staticClass:"can-disable-selector error--text",attrs:{"placeholder":"Select End Time","items":_vm.timeSlots,"outlined":"","color":"primary500Color","dense":"","item-text":"text","item-value":"value","hide-details":true,"clearable":_vm.timeslotObj.is_final ? true : false,"append-icon":"mdi-clock-outline","rules":[_vm.endTimeValidation],"disabled":!_vm.hasPermEdit},model:{value:(_vm.timeslotObj.end_time),callback:function ($$v) {_vm.$set(_vm.timeslotObj, "end_time", $$v)},expression:"timeslotObj.end_time"}}),_c('p',{staticClass:"value-message edit-error-message small mb-0",style:({
        visibility:
          _vm.timeslotObj.end_time <= _vm.timeslotObj.start_time &&
          _vm.timeslotObj.start_time &&
          _vm.timeslotObj.end_time
            ? 'visible'
            : 'hidden'
      })},[_vm._v(" Endtime must be greater than start time ")])],1),_c('v-col',{attrs:{"t-data":"info-input"}},[_c('span',{staticClass:"edit-field-label sub-body-bold",attrs:{"t-data":"info-label"}},[_vm._v(" Remark ")]),_c('v-text-field',{staticClass:"can-disable-selector",attrs:{"outlined":"","dense":"","maxlength":25,"hide-details":true,"clearable":_vm.timeslotObj.is_final ? true : false,"disabled":!_vm.hasPermEdit},model:{value:(_vm.timeslotObj.remark),callback:function ($$v) {_vm.$set(_vm.timeslotObj, "remark", $$v)},expression:"timeslotObj.remark"}}),_c('p',{staticClass:"value-message small mb-0 secondaryTextColor--text"},[_vm._v(" Maximum 25 characters ")])],1),_c('v-col',{staticClass:"mt-0",attrs:{"t-data":"info-input"}},[_c('p',{staticClass:"edit-field-label sub-body-bold",attrs:{"t-data":"info-label"}},[_vm._v(" Tooltip "),_c('span',{staticClass:"danger500Color--text"})]),_c('v-text-field',{staticClass:"can-disable-selector",attrs:{"outlined":"","dense":"","maxlength":70,"hide-details":true,"clearable":_vm.timeslotObj.is_final ? true : false,"disabled":!_vm.hasPermEdit},model:{value:(_vm.timeslotObj.tooltip),callback:function ($$v) {_vm.$set(_vm.timeslotObj, "tooltip", $$v)},expression:"timeslotObj.tooltip"}}),_c('p',{staticClass:"value-message small mb-0 secondaryTextColor--text"},[_vm._v(" Maximum 70 characters ")])],1),_c('v-col',{attrs:{"cols":"1","t-data":"info-input"}},[_c('span',{staticClass:"edit-field-label sub-body-bold",attrs:{"t-data":"info-label"}}),(_vm.timeslotObj.is_final)?_c('v-icon',{staticClass:"add-icon",attrs:{"right":"","disabled":_vm.timeslotObj.start_time &&
        _vm.timeslotObj.end_time &&
        _vm.timeslotObj.end_time > _vm.timeslotObj.start_time
          ? false
          : true},on:{"click":function($event){return _vm.addNewSchedule()}}},[_vm._v("mdi-plus-circle-outline")]):_c('v-icon',{attrs:{"right":"","disabled":!_vm.hasPermEdit},on:{"click":function($event){return _vm.removeSchedule()}}},[_vm._v("mdi-trash-can-outline")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }